import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { backendURL, cookieDomain } from '../../constants/environmental';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import { useAuthHeader } from 'react-auth-kit';
import { batchArray } from '../../utils/array';
import ImageWithFallback from '../../components/ImageWithFallback';

const RecommendedToolsCarousel = ({ blockId }: { blockId: string }) => {
  const [carouselInViewport, setCarouselInViewport] = useState<boolean>(false);
  const [incrementingViews, setIncrementingViews] = useState<boolean>(false);
  const [recommendedToolsViewedStatus, setRecommendedToolsViewedStatus] =
    useState<any>();

  const [recommendedToolsClickedStatus, setRecommendedToolsClickedStatus] =
    useState<any>();

  const [currentRecommendedToolIds, setCurrentRecommendedToolIds] = useState<
    any[] | undefined
  >();

  const [recommendedTools, setRecommendedTools] = useState<any[] | undefined>();

  const authHeader = useAuthHeader()();

  const [, setCookie] = useCookies(['recommendationSourceToolId', 'l_id']);

  useEffect(() => {
    loadRecommendedTools();
  }, []);

  useEffect(() => {
    // increment views at most once for each tool per page load
    if (
      !carouselInViewport ||
      currentRecommendedToolIds === undefined ||
      recommendedToolsViewedStatus?.[currentRecommendedToolIds[0]] ||
      incrementingViews
    ) {
      return;
    }
    setIncrementingViews(true);
    Promise.all(
      currentRecommendedToolIds.map(currentRecommendedToolId =>
        axios.patch(
          `${backendURL}/recommendation/${blockId}/${currentRecommendedToolId}/incrementViews`,
          {},
          {
            headers: {
              Authorization: authHeader,
            },
          }
        )
      )
    )
      .then(() => {
        // console.log('incremented views');
        for (const currentRecommendedToolId of currentRecommendedToolIds) {
          recommendedToolsViewedStatus[currentRecommendedToolId] = true;
        }
      })
      .catch(() => console.log('error incrementing views'))
      .finally(() => setIncrementingViews(false));
  }, [carouselInViewport, currentRecommendedToolIds]);

  const carouselRef = useCallback((element: any) => {
    if (!element) return;

    const observer = new IntersectionObserver(([observedElement]) => {
      setCarouselInViewport(observedElement.isIntersecting);
    });

    observer.observe(element);

    // return () => observer.disconnect();
  }, []);

  return !recommendedTools?.length ? null : (
    <div
      ref={carouselRef}
      className=' flex flex-col gap-2 bg-white recommendations-container pt-6'
    >
      <h2>Users Also Liked:</h2>
      <div className='show-arrows'>
        <Carousel
          autoPlay
          infiniteLoop
          interval={10000}
          useKeyboardArrows
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          /* allow touchscreen users to scroll down if the
             recommendations cover the screen */
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={20}
        >
          {batchArray(4, recommendedTools).map((toolBatch, index) => {
            return (
              <div
                className='grid grid-cols-2 justify-evenly lg:grid-cols-4 gap-2'
                key={index}
              >
                {toolBatch.map((tool: any) => (
                  <div
                    className='flex flex-col gap-2 carousel-image-container'
                    key={tool._id}
                  >
                    <ImageWithFallback
                      src={tool.coverPhoto}
                      height={48}
                    />
                    <div className='flex flex-col gap-2'>
                      <p className='leading-normal'>{tool.name}</p>
                      <Link
                        className='cursor-pointer underline text-blue-700'
                        to={`/fusion/${tool._id}`}
                        target='_blank'
                        onClick={() => onRecommendedToolClick(tool._id)}
                      >
                        Open in new tab
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );

  async function onRecommendedToolClick(toolId: string) {
    // increment clicks at most once for each tool per page load
    if (
      !carouselInViewport ||
      !currentRecommendedToolIds ||
      recommendedToolsClickedStatus?.[toolId]
    ) {
      return;
    }
    setCookie('recommendationSourceToolId', blockId, {
      // expires in 1 hour
      expires: new Date(new Date().getTime() + 60 * 60 * 1000),
      path: '/', // make sure the cookie is sent from all paths in the site
      domain: cookieDomain,
    });
    axios
      .patch(
        `${backendURL}/recommendation/${blockId}/${toolId}/incrementClicks`,
        {},
        {
          headers: {
            Authorization: authHeader,
          },
        }
      )
      .then(() => {
        recommendedToolsClickedStatus[toolId] = true;
      })
      .catch(() => console.log('error incrementing clicks'));
  }

  async function loadRecommendedTools() {
    try {
      const recommendedToolsResponse = await axios.get(
        `${backendURL}/blocks/${blockId}/recommendedTools`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );

      setRecommendedTools(recommendedToolsResponse.data);
      setRecommendedToolsViewedStatus(
        Object.fromEntries(
          recommendedToolsResponse.data.map(({ _id }: any) => [_id, false])
        )
      );
      setRecommendedToolsClickedStatus(
        Object.fromEntries(
          recommendedToolsResponse.data.map(({ _id }: any) => [_id, false])
        )
      );
      if (recommendedToolsResponse.data.length === 0) {
        return;
      }
      setCurrentRecommendedToolIds(
        recommendedToolsResponse.data.slice(0, 4).map(({ _id }: any) => _id)
      );
    } catch (error) {
      console.log('Error loading recommended tools');
    }
  }
};

export default RecommendedToolsCarousel;
