import React, { FC, useContext } from "react";
import axios from "axios";
import { useAuthHeader } from "react-auth-kit";
import ReactGA from "react-ga4";
import FuserContext from "../../context/FuserContext";
import MyToolTips from "../../components/MyTooltip";
import Toggle from "../../components/Toggle";
import { backendURL } from "../../constants/environmental";

const IsAdminPublishedSection: FC<{}> = () => {
  const { toolId } = useContext(FuserContext);

  const {
    toolMetadata: { isPublished },
    updateToolMetadata,
    saveBlock,
  } = useContext(FuserContext);

  const authHeader = useAuthHeader()();

  const togglePublish = async () => {
    await saveBlock();

    try {
      await axios.patch(
        `${backendURL}/blocks/admin-publish/${toolId}`,
        {},
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );

      updateToolMetadata({
        isPublished: !isPublished,
        isRequestPublished: false,
      });

      ReactGA.event("publish_toggle");
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    togglePublish();
  };

  return (
    <label className="flex items-center cursor-pointer">
      <Toggle toggled={isPublished} onChange={handleToggle} />
      <div
        className={`ml-3 text-gray-700 font-medium ${
          isPublished ? "text-green-500" : "text-red-500"
        }`}
      >
        {isPublished ? "Published By Admin" : "Admin Approved"}
      </div>{" "}
      &nbsp;{" "}
      <MyToolTips
        content="
        <p>Admin Recommendations:</p>
        <p>Is it more than just a prompt, 3+ steps minimum</p>
        <p>Check for anything malicious or spammy</p>
        <p>Check for anything that may get us in trouble with openai like inapproiate requests</p>
        <p>Does it do what the title and description say?</p>
        <p>Is it high quality, is it unique from other tools?</p>
        <p>Is the price reasonable based on what the tool can do?</p>
        "
        tipID="block-types"
        datatooltipplace="left"
      />
    </label>
  );
};

export default IsAdminPublishedSection;
