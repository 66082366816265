import React from "react";
import BgGlassmorphism from "../../components/BgGlassmorphism/BgGlassmorphism";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import PageSearch from "../PageSearch";
import { FaChevronCircleRight } from "react-icons/fa";

function PageHomeLoggedOut() {
  const navigate = useNavigate();

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Skillfusion || The AI Tools Marketplace</title>
        <link rel="canonical" href="https://skillfusion.ai" />
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-20 mt-12 mb-20 sm:space-y-24 lg:space-y-32">
        {/* SECTION HERO */}

        <div
          className={`nc-SectionHero relative pb-10`}
          data-nc-id="SectionHero"
        >
          <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 lg:items-start relative">
            <div className="w-screen max-w-full xl:max-w-2/3 space-y-5 lg:space-y-7">
              <h2 className="text-xl !leading-2 font-sans">
                <span>
                  <h1 className="text-3xl">
                    Skillfusion - Speed up Your Success With The Power of AI
                  </h1>
                  <br />

                  <p>- 100 AI Tools currently available to use now</p>
                  <p>- Free tool maker where you can make your own AI Tools</p>
                  <br />

                  <p>
                    Write entire books with a single command, generate unlimited
                    web content, summarise, translate and rewrite long
                    documents...
                  </p>
                  <br />

                  <h2>
                    <FaChevronCircleRight
                      className="text-indigo-600"
                      style={{ display: "inline" }}
                    />{" "}
                    <a
                      onClick={() => navigate("/signup")}
                      className="text-indigo-600 underline underline-offset-4 cursor-pointer"
                    >
                      Register Now
                    </a>
                    <br />
                    <FaChevronCircleRight
                      className="text-indigo-600"
                      style={{ display: "inline" }}
                    />{" "}
                    <a
                      onClick={() => navigate("/page-search?price=free")}
                      className="text-indigo-600 underline underline-offset-4 cursor-pointer"
                    >
                      Browse Free Tools
                    </a>
                    <br />
                    <FaChevronCircleRight
                      className="text-indigo-600"
                      style={{ display: "inline" }}
                    />{" "}
                    <a
                      onClick={() => navigate("/page-search?price=paid")}
                      className="text-indigo-600 underline underline-offset-4 cursor-pointer"
                    >
                      Browse Paid Tools
                    </a>
                    <br />
                    <FaChevronCircleRight
                      className="text-indigo-600"
                      style={{ display: "inline" }}
                    />{" "}
                    <a
                      onClick={() => navigate("/fuser/0")}
                      className="text-indigo-600 underline underline-offset-4 cursor-pointer"
                    >
                      Make & Publish your own tools
                    </a>
                  </h2>

                  <p className="text-3xl py-4">
                    Do 10x more than you can with ChatGPT alone
                  </p>

                  <p>
                    ✔️ Tools created and vetted by real humans <br />
                    ✔️ Multiple prompts combined <br />
                    ✔️ Web access <br />
                    ✔️ Image generation <br />
                    ✔️ Embedded knowledge <br />
                    ✔️ Zapier connection <br />
                    ✔️ Google search <br />
                    ✔️ Longer output
                  </p>
                </span>
              </h2>
              <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 max-w-lg">
                {/*subHeading*/}
              </span>
            </div>
            <div className="flex-grow">
              <PageSearch mode="preview" />
            </div>
          </div>
        </div>

        {/* SECTION 2 */}
        {/*<SectionHowItWork />*/}
      </div>

      {/* SECTION LAERGE SLIDER */}
    </div>
  );
}

export default PageHomeLoggedOut;
