import { FC, FormEvent, useState, useRef } from 'react';
// import facebookSvg from 'images/Facebook.svg';
// import googleSvg from 'images/Google.svg';
import { Helmet } from 'react-helmet';
import Input from '../../shared/Input/Input';
import ButtonPrimary from '../../shared/Button/ButtonPrimary';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// import { FacebookAuthProvider, GoogleAuthProvider, TwitterAuthProvider } from "@firebase/auth";
import ErrorPopup from '../../components/ErrorPopup';
import ReactGA from 'react-ga4';
import { useAuthUser, useSignIn } from 'react-auth-kit';
import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import RedditPixel from 'react-reddit-pixel';
import { useCookies } from 'react-cookie';
import { backendURL } from '../../constants/environmental';

export interface PageSignUpProps {
  className?: string;
  inModal?: boolean;
  onVerify?: (email: string, tempId: string) => void;
}

//const PASSWORD_REGEX = /^(?=.*[a-zA-Z]).{8,}$/;
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const letterRegex = /\p{L}/u;
const nameRegex = /^[\p{L}\d \-_.]+$/u;
// const numberRegex = /\d/;
const MIN_PASSWORD_LENGTH = 8;

const PageSignUp: FC<PageSignUpProps> = ({
  className = '',
  inModal = false,
  onVerify = () => {},
}) => {
  // const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const user = useAuthUser()();

  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);

  const [email, setEmail] = useState('');

  const [formErrorMessageVisibile, setFormErrorMessageVisibile] = useState<any>(
    {
      name: false,
      email: false,
      password: false,
      form: false,
    }
  );

  const showAllErrorMessages = () =>
    setFormErrorMessageVisibile((previousVisible: any) => {
      const newVisible: any = {};
      for (const key in previousVisible) newVisible[key] = true;
      return newVisible;
    });

  const showErrorMessage = (formField: string) =>
    setFormErrorMessageVisibile((previous: any) => ({
      ...previous,
      [formField]: true,
    }));

  const [name, setName] = useState('');

  const [signupError, setSignupError] = useState<string | null>(null);

  const handleCloseError = () => {
    // console.log('close error');
    setSignupError(null);
  };

  const [password, setPassword] = useState('');

  const passwordRef = useRef<HTMLInputElement>(null);

  const passwordValidations: any = {
    longEnough: password.length >= MIN_PASSWORD_LENGTH,
    containsLetters: letterRegex.test(password),
    //containsNumbers: numberRegex.test(password), // we don't need this atm
  };

  const passwordErrorMessages: any = {
    longEnough: `Password needs to be at least ${MIN_PASSWORD_LENGTH} characters long`,
    containsLetters: 'Password must contain at least one letter',
    containsNumbers: 'Password must contain at least one number',
  };

  const passwordErrorMessagesToDisplay = Object.keys(passwordValidations)
    .filter(criterion => !passwordValidations[criterion])
    .map(criterion => passwordErrorMessages[criterion]);

  const formValidations = {
    name: nameRegex.test(name.trim()) && letterRegex.test(name),
    email: EMAIL_REGEX.test(email),
    password: Object.values(passwordValidations).every(value => value === true),
  };

  const formIsValid = Object.values(formValidations).every(
    value => value === true
  );

  const navigate = useNavigate();

  const nameClasses = `border ${
    formErrorMessageVisibile.name && !formValidations.name
      ? 'border-red-800 dark:border-red-800 border-4'
      : 'border-gray-300 dark:border-gray-300'
  } mt-1`;
  const emailInputClasses = `border ${
    formErrorMessageVisibile.email && !formValidations.email
      ? 'border-red-800 dark:border-red-800 border-4'
      : 'border-gray-300 dark:border-gray-300'
  } mt-1`;
  const passwordInputClasses = `border ${
    formErrorMessageVisibile.password && !formValidations.password
      ? 'border-red-800 dark:border-red-800 border-4'
      : 'border-gray-300 dark:border-gray-300'
  } mt-1`;
  // const signIn = useSignIn();

  // console.log(
  //   'name error showing:',
  //   formErrorMessageVisibile.name && !formValidations.name
  // );

  const [signupButtonText, setSignupButtonText] = useState('Sign Up Now');
  const [showPassword, setShowPassword] = useState(false);

  const [cookies] = useCookies(['referrerId', 'referralSource']);

  const createAccount = async () => {
    const name = nameRef.current!.value;
    const email = emailRef.current!.value;
    const password = passwordRef.current!.value;
    if (!formIsValid) {
      // console.log('form is not valid');
      showAllErrorMessages();
      if (!formValidations.password) {
        console.error('Password failed validation');
        ReactGA.event('failed_sign_up_attempt_invalid_password', {
          error: 'password failed validation',
        });
      }
      if (!formValidations.email) {
        console.error('Email failed validation');
        ReactGA.event('failed_sign_up_attempt_invalid_email', {
          error: 'email failed validation',
        });
      }
      if (!formValidations.name) {
        console.error('Name failed validation_invalid_name');
        ReactGA.event('failed_sign_up_attempt', {
          error: 'name failed validation',
        });
      }
      return;
    }

    setSignupButtonText('Signing up...');
    // Call the backend endpoint to create a new account
    // console.log('email sent to register backend:', email);
    try {
      const body: any = {
        name,
        email,
        password,
        referrerId: cookies.referrerId,
        referralSource: cookies.referralSource,
        ...(user?.trialAccount && { trialAccountId: user.id }),
      };

      const res = await axios.post(`${backendURL}/user/register`, body, {
        withCredentials: true,
      });
      //console.log(res.data); // Log the response data for debugging

      console.log('got data back');
      // let loggedInUserId;
      // If account creation was successful, try to log in the user
      if (res.data.message) {
        const tempID = res.data.message.slice(30);

        ReactGA.event('successful_sign_up', {});
        //ReactGA.event({category:"sign_up",action:"successful_sign_up"}); // not tested yet
        ReactPixel.track('CompleteRegistration', {
          currency: 'USD',
          value: 1.0,
        });
        RedditPixel.track('SignUp');

        console.log('finished tracking pixels');

        if (!inModal) {
          navigate(`/verify-email/${tempID}`, {
            state: {
              verifyEmailMessage: `A 6-digit code has been sent to your email ${email}, please enter the code here to verify your email and receive 100 free credits.`,
            },
          });
        } else {
          onVerify(email, tempID);
        }
      }

      console.log('end');
    } catch (registerError: any) {
      // console.error('Error creating account', registerError);
      // console.log(registerError);
      // track failed signup
      ReactGA.event('failed_sign_up_attempt_backend_error', {});

      setSignupError(`${registerError.response.data.error}`);
      setSignupButtonText('Sign Up Now');
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await createAccount();
  };

  // const handleGoogleSignUp = async (googleData: any) => {
  //   try {
  //     //       const response = await window.gapi.auth2.getAuthInstance().signIn();
  //     // const id_token = response.getAuthResponse().id_token;

  //     const res = await axios.post(`${backendURL}/user/google-login`, {
  //       token: googleData.tokenId,
  //     });

  //     const data = res.data;
  //     const success = signIn({
  //       token: data.token,
  //       expiresIn: data.expiresIn,
  //       tokenType: 'Bearer',
  //       authState: data.authUserState,
  //     });

  //     if (success) {
  //       navigate(`/page-search`);
  //     } else {
  //       console.error('Sign in failed');
  //     }
  //   } catch (error) {
  //     console.error('Error during Google sign-up:', error);
  //   }
  // };

  const signOut = async () => {};

  // const loginSocials = [
  //   {
  //     name: 'Continue with Facebook',
  //     function: HandleFacebookSignUp,
  //     icon: facebookSvg,
  //   },

  //   {
  //     name: 'Continue with Google',
  //     function: handleGoogleSignUp,
  //     icon: googleSvg,
  //   },
  // ];

  return (
    <div
      className={`nc-PageSignUp  ${className}`}
      data-nc-id='PageSignUp'
    >
      {!user || user.trialAccount ? (
        <>
          <Helmet>
            <title>Sign up || SkillFusion</title>
            <link
              rel='canonical'
              href='https://skillfusion.ai/signup'
            />
          </Helmet>
          <div className='container mb-24 lg:mb-32'>
            <h2 className='my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center'>
              Sign Up Now
            </h2>

            {/*             
              <h2 className='my-10 text-2xl font-bold text-blue-400 text-3xl text-center'>
                Get 30 Free Credits Today
              </h2>
            */}
            <h3 className='my-10 flex items-center text-lg text-center leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center'>
              Get instant access to over 100 AI tools
            </h3>
            {user?.trialAccount && (
              <p className='my-10 flex items-center text-lg text-center leading-[115%] md:leading-[115%] text-neutral-900 dark:text-neutral-100 justify-center'>
                All of your saved data will be transferred to your new account.
              </p>
            )}
            <div className='max-w-md mx-auto space-y-6 '>
              {navigator.cookieEnabled || (
                <ErrorPopup
                  message={
                    'Please enable cookies in your browser otherwise this website will not function properly.'
                  }
                  onClose={handleCloseError}
                />
              )}
              {/* <div className="grid gap-3">

                    {loginSocials.map((item, index) => (
                      <button
                        key={index}
                        onClick={item.function}
                        className=" flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                      >
                        <img
                          className="flex-shrink-0"
                          src={item.icon}
                          alt={item.name}
                        />
                        <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                          {item.name}
                        </h3>
                      </button>
                    ))}
                  </div>
                    <div className="relative text-center">
                      <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                        OR
                      </span>
                    <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
                  </div> */}

              {/* FORM */}
              <form
                onSubmit={handleSubmit}
                className='grid grid-cols-1 gap-6 '
              >
                <label className='block'>
                  <span className='text-neutral-800 dark:text-neutral-200'>
                    Display name
                  </span>
                  <Input
                    type='text'
                    placeholder='Display name'
                    className={nameClasses}
                    ref={nameRef}
                    onChange={e => setName(e.target.value)}
                    onBlur={e => showErrorMessage('name')}
                  />
                  {formErrorMessageVisibile.name && !formValidations.name && (
                    <p className='text-sm'>
                      Display name must contain at least 1 letter, and can only
                      contain characters which are letters, numbers, spaces,
                      dashes, underscores, or periods.
                    </p>
                  )}
                </label>

                <label className='block'>
                  <span className='text-neutral-800 dark:text-neutral-200'>
                    Email address
                  </span>
                  <Input
                    type='email'
                    placeholder='example@example.com'
                    className={emailInputClasses}
                    ref={emailRef}
                    onChange={e => setEmail(e.target.value)}
                    onBlur={e => showErrorMessage('email')}
                  />
                  {formErrorMessageVisibile.email && !formValidations.email && (
                    <p className='text-sm'>Invalid email address</p>
                  )}
                  {/*<span style={{fontSize:"10pt",color:"darkred"}}>(You will need to verify your email to get the free credits)</span>*/}
                </label>

                <label className='block'>
                  <span className='flex justify-between items-center text-neutral-800 dark:text-neutral-200'>
                    Password
                  </span>

                  <div className='flex gap-2 items-center'>
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      placeholder='Password'
                      className={passwordInputClasses}
                      ref={passwordRef}
                      onChange={e => setPassword(e.target.value)}
                      onBlur={e => showErrorMessage('password')}
                    />
                    <button
                      className='text-blue-700'
                      type='button'
                      onClick={() => setShowPassword(previous => !previous)}
                    >
                      {showPassword ? 'Hide' : 'Show'}
                    </button>
                  </div>

                  {formErrorMessageVisibile.password &&
                  !formValidations.password ? (
                    <ul className='text-black-500 mt-1 text-xs'>
                      {passwordErrorMessagesToDisplay.map(
                        (errorMessage, index) => {
                          return (
                            <li
                              key={index}
                              className='text-sm'
                            >
                              {errorMessage}
                            </li>
                          );
                        }
                      )}
                    </ul>
                  ) : (
                    <ul className='text-xs flex flex-col px-4 gap-1 py-2 jutify-start'>
                      <label className='border-b border-black py-1 '>
                        Password Requirements
                      </label>

                      <li className={``}>
                        Must contain at least 8 characters and 1 letter
                      </li>
                      {/*<li className={``}>Must include one Uppercase letter </li>*/}
                    </ul>
                  )}
                </label>

                {signupError && (
                  <ErrorPopup
                    message={signupError}
                    onClose={handleCloseError}
                  />
                )}

                <ButtonPrimary type='submit'>{signupButtonText}</ButtonPrimary>
                {formErrorMessageVisibile.form && !formIsValid && (
                  <p>
                    The form has some invalid fields, please correct them before
                    attempting to sign up
                  </p>
                )}
              </form>

              {/* ==== */}
              <Link
                to='/login'
                className='block text-center text-neutral-700 dark:text-neutral-300 font-bold text-lg hover:scale-110'
              >
                Already have an account? {` `}
                <span className='text-green-600'>Sign in</span>
              </Link>
            </div>
          </div>
        </>
      ) : (
        <div className='container flex items-center flex-col justify-center gap-4 m-2'>
          <h2>You are currently signed in as : {user?.username} </h2>
          <div className='flex gap-4'>
            {/* <ButtonPrimary onClick={signOut}>Sign out</ButtonPrimary> */}
            <ButtonPrimary
              className='bg-purple-400'
              onClick={() => navigate(`/profile/${user?.id}`)}
            >
              My Profile
            </ButtonPrimary>
          </div>
        </div>
      )}
    </div>
  );
};

export default PageSignUp;
