import React, { FC, useContext, useEffect, useRef, useState } from "react";
import FuserLoader from "../../containers/FuserPage/FuserLoader";
import BlockProps from "../../models/BlockProps";
import FuserContext from "../../context/FuserContext";
import useBlockRunner from "../../hooks/useBlockRunner";
import MyToolTips from "../MyTooltip";
import AutocompleteTextarea from "../../containers/FuserPage/AutocompleteTextarea";
import axios from "axios";
import { useAuthHeader } from "react-auth-kit";
import { testButtonStyles } from "../../constants/styles";
import {
  ensureArray,
  ensureNotArray,
  is2dArray,
  transpose,
  updateAtIndexRun,
} from "../../utils/array";
import { backendURL } from "../../constants/environmental";
import { truncateAfter } from "../../utils/string";
import { MAX_PREVIEW_CHARS, blockReferenceRegex } from "../../constants/blocks";
import { evaluatePlaceholder, replacePlaceholders } from "../../utils/fuser";

const DownloadBlock: FC<BlockProps> = ({
  isLoading,
  index,
  block,
  setIsLoading,
  handleChange,
  collapsed,
  updateBlocks,
}) => {
  const {
    blockStyles,
    blocks,
    runnerMode,
    setActivityLog,
    runnerIndex,
    setBlocks,
    saveRunnerModeBlockData,
    toolMetadata: { autosaveMode },
  } = useContext(FuserContext);

  useBlockRunner(() => {}, index);

  const [statusMessage, setStatusMessage] = useState<any>("");

  useEffect(() => {
    setStatusMessage("");
  }, [runnerMode]);

  useEffect(() => {
    if (index == runnerIndex) {
      setBlocks(updateAtIndexRun(index, block, blocks)); // don't need to wait for this block in runner mode
      setStatusMessage("Ready to download");
    }
  }, [runnerIndex]);

  const googleDocFormatRef = useRef<HTMLSelectElement | null>(null);

  const [previewedGoogleDocId, setPreviewedGoogleDocId] = useState<any>();

  const [dataForGoogleDocWithId, setDataForGoogleDocWithId] = useState<
    Record<string, Record<string, string>> | undefined
  >({});

  useEffect(() => {
    if (dataForGoogleDocWithId === undefined) {
      loadGoogleDocData();
    }
  }, [block.data.googleDocIds]);

  useEffect(() => {
    loadGoogleDocData();
    setPreviewedGoogleDocId(undefined);
  }, [runnerMode]);

  const [exportToGoogleDocButtonText, setExportToGoogleDocButtonText] =
    useState<string>("Export to Google doc");

  const authHeader = useAuthHeader()();

  const { downloadName, message } = block.data;

  let processedMessage = replacePlaceholders(message ?? "", blocks);
  if (typeof processedMessage !== "string") {
    processedMessage = processedMessage?.[0] ?? "";
  }

  if (collapsed) {
    return (
      <div>
        {message
          ? truncateAfter(MAX_PREVIEW_CHARS, processedMessage)
          : "Download block"}
      </div>
    );
  }

  // this caused length error:
  // console.log(
  //   MAX_PREVIEW_CHARS,
  //   truncateAfter(MAX_PREVIEW_CHARS, processedMessage)
  // );

  const processedDownloadName =
    (replacePlaceholders(downloadName, blocks)
      ?.toString()
      .trim()
      .slice(0, 50) || `Block_${index}`) +
    `.${block.data.selectValue ?? "txt"}`;

  return (
    <FuserLoader name="Download Block" loading={isLoading}>
      <div className={blockStyles} key={index}>
        {runnerMode || (
          <>
            <label>What to download</label>

            <AutocompleteTextarea
              block={block}
              index={index}
              onChange={handleChange}
              textAreaIndex={0}
              className="w-full prompt-textarea resize-none bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner "
              name="inputToProcess"
              value={block.data.inputToProcess || ""}
            />

            <label className="text-xs">Optional heading for user:</label>

            <AutocompleteTextarea
              autosize={true}
              block={block}
              index={index}
              onChange={handleChange}
              textAreaIndex={1}
              className="w-full resize-none bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner"
              name="message"
              value={message ?? ""}
              placeholder="Heading for user"
            />
            <br />
          </>
        )}

        <p>
          {message
            ? truncateAfter(MAX_PREVIEW_CHARS, processedMessage)
            : "Download options:"}
        </p>

        <div className="download-options flex flex-wrap gap-2 items-center justify-center">
          {!runnerMode && (
            <>
              <select
                className="rounded-xl text-xs h-10"
                name="selectValue"
                value={block.data.selectValue || ""}
                onChange={handleChange}
              >
                <option value="txt">TXT file</option>
                <option value="google-doc">Google doc</option>
                <option value="json">JSON file</option>
                <option value="csv">CSV file</option>
                <option value="tsv">TSV file</option>
                <option value="html">HTML file</option>
                <option value="docx">DOCX file</option>
              </select>
              {["csv", "tsv"].includes(block.data.selectValue) && (
                <MyToolTips
                  content="<div>Please input either a 2d array or references to 1d arrays placed side by side, with an optional line of headings separated by commas. For example:</div>
              <pre> Heading0,Heading1,Heading2
              <0:output><1:output><2:output></pre>"
                  tipID={index.toString() + "-block-types-definition"}
                  datatooltipplace="left"
                />
              )}
            </>
          )}
          {block.data.selectValue === "google-doc" ? (
            <button
              className="text-md text-wrap border-black border-2 bg-blue-100 p-2 px-6 rounded-xl cursor-pointer "
              onClick={async () => {
                try {
                  setExportToGoogleDocButtonText("Exporting...");

                  const title = ensureNotArray(
                    replacePlaceholders(block.data.googleDocTitle, blocks)
                  );

                  const response = await axios.post(
                    `${backendURL}/google/docs`,
                    {
                      title,
                      body: ensureNotArray(
                        replacePlaceholders(block.data.inputToProcess, blocks)
                      ),
                      formatting: block.data.googleDocFormat,
                    },
                    { headers: { Authorization: authHeader } }
                  );
                  const googleDocId = response.data;

                  setExportToGoogleDocButtonText("Export to Google doc");

                  block.data.googleDocIds.push(googleDocId);
                  setDataForGoogleDocWithId((previous) => ({
                    ...(previous ?? {}),
                    [googleDocId]: {
                      title: title || "Untitled",
                      date: new Date().toLocaleString(),
                    },
                  }));
                  updateBlocks();
                  if (runnerMode && autosaveMode) {
                    saveRunnerModeBlockData(block);
                  }
                } catch (error) {
                  setExportToGoogleDocButtonText(
                    "Error exporting to Google doc. Click to try again."
                  );
                }
              }}
            >
              {exportToGoogleDocButtonText}
            </button>
          ) : (
            <a
              className="text-md text-wrap border-black border-2 bg-blue-100 p-2 px-6 rounded-xl cursor-pointer "
              href={block.data.downloadLink}
              //download={`Block_${index}.${block.data.selectValue}`}
              onClick={onDownloadClick}
            >
              <b>Download</b>
              {` "${processedDownloadName}"`}
            </a>
          )}
        </div>

        {!runnerMode &&
          (block.data.selectValue !== "google-doc" ? (
            <div className="download-options grid grid-cols-[1fr] sm:grid-cols-[auto_1fr] gap-2 items-center justify-center">
              <label className="text-sm">
                Download name (optional, 50 character limit):
              </label>
              {/* <input
                  className='p-1 border border-black rounded-lg text-sm w-max grow'
                  name='downloadName'
                  value={downloadName ?? ''}
                  onChange={handleDownloadNameChange}
                /> */}
              <AutocompleteTextarea
                autosize={true}
                block={block}
                index={index}
                onChange={handleChange}
                textAreaIndex={2}
                className="p-1 border border-black rounded-lg text-sm w-full 2xl:w-3/4"
                containerClassName="w-full grow flex"
                name={"downloadName"}
                value={downloadName ?? ""}
              />
              {block.data.selectValue === "docx" && (
                <>
                  <label className="text-sm">
                    Creator name (optional, 50 character limit):
                  </label>
                  <AutocompleteTextarea
                    autosize={true}
                    block={block}
                    index={index}
                    onChange={handleChange}
                    textAreaIndex={3}
                    className="p-1 border border-black rounded-lg text-sm w-full 2xl:w-3/4"
                    containerClassName="w-full grow flex"
                    name={"creator"}
                    value={block.data.creator ?? ""}
                  />
                </>
              )}
            </div>
          ) : (
            <div className="flex flex-wrap items-center gap-4">
              <div className="flex flex-wrap items-center gap-4 grow">
                <label className="text-sm">
                  Title (50 character limit):{" "}
                  {/* <input
                className='p-1 border border-black rounded-lg'
                ref={googleDocTitleRef}
              /> */}
                </label>
                <AutocompleteTextarea
                  autosize={true}
                  block={block}
                  index={index}
                  onChange={handleChange}
                  textAreaIndex={2}
                  className="p-1 border border-black rounded-lg text-sm w-max grow"
                  containerClassName="w-max grow flex"
                  name={"googleDocTitle"}
                  value={block.data.googleDocTitle ?? ""}
                  placeholder="Enter title here..."
                />
              </div>

              {/* <label>
            Gmail address (optional, please sign in on another tab first): <input />
          </label> */}
              <div className="flex flex-wrap items-center gap-4">
                <label className="text-sm">Formatting:</label>
                <select
                  className="rounded-lg"
                  ref={googleDocFormatRef}
                  name="googleDocFormat"
                  value={block.data.googleDocFormat || ""}
                  onChange={handleChange}
                >
                  <option value="plain text">Plain text</option>
                  <option value="html">HTML</option>
                </select>
              </div>
            </div>
          ))}

        {/* {exportToGoogleDocError && <p>{exportToGoogleDocError}</p>} */}

        {!runnerMode && block.data.selectValue === "google-doc" && (
          <label
            className="flex gap-2 items-center"
            htmlFor="pauseBetweenOption"
          >
            <input
              type="checkbox"
              name="giveOptionToPreview"
              checked={block.data.giveOptionToPreview}
              onChange={() => {
                block.data.giveOptionToPreview =
                  !block.data.giveOptionToPreview;
                updateBlocks();
              }}
            />{" "}
            Give users the option to preview exported docs here
          </label>
        )}

        {block.data.selectValue === "google-doc" &&
          block.data.googleDocIds?.length > 0 && (
            <>
              <p>Docs made:</p>
              <ul className="flex flex-col gap-4">
                {block.data.googleDocIds.map((id: string) => (
                  <li key={id} className="flex flex-wrap gap-x-4">
                    {dataForGoogleDocWithId?.[id] && (
                      <>
                        <p>{`(${dataForGoogleDocWithId[id].date})`}</p>
                        <p>{`${dataForGoogleDocWithId[id].title}`}</p>
                      </>
                    )}
                    {block.data.giveOptionToPreview && (
                      <a
                        className={
                          previewedGoogleDocId === id
                            ? ""
                            : "cursor-pointer underline text-blue-700"
                        }
                        onClick={() => setPreviewedGoogleDocId(id)}
                      >
                        {previewedGoogleDocId === id
                          ? "(Previewing)"
                          : "Preview"}
                      </a>
                    )}
                    {/* <a
                    className='cursor-pointer underline text-blue-700'
                    href={getLinkToGoogleDocWithId(id)}
                    target='_blank' rel="noreferrer"
                  >
                    Open in new tab
                  </a> */}
                    <a
                      className="cursor-pointer underline text-blue-700"
                      href={getCopyLinkToGoogleDocWithId(id)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Copy to your Google Drive{" "}
                    </a>
                    {/* <a
                    className='cursor-pointer underline text-blue-700'
                    onClick={() => {
                      axios
                        .delete(
                          `${backendURL}/google/docs/${id}`
                        )
                        .then(() => {
                          block.data.googleDocIds =
                            block.data.googleDocIds.filter(
                              (otherGoogleDocId: string) =>
                                otherGoogleDocId !== id
                            );
                          updateBlocks();
                          if (runnerMode && autosaveMode) {
                            saveRunnerModeBlockData(block);
                          }
                          setDataForGoogleDocWithId(previous => {
                            delete previous[id];
                            return previous;
                          });
                          if (previewedGoogleDocId === id) {
                            setPreviewedGoogleDocId(
                              block.data.googleDocIds.at(-1)
                            );
                          }
                        })
                        .catch(() => console.log('delete doc failed'));
                    }}
                  >
                    Delete
                  </a> */}
                  </li>
                ))}
              </ul>
              {previewedGoogleDocId && (
                <>
                  <br />
                  <p className="flex justify-between">
                    <span>Preview:</span>
                    <a
                      onClick={() => setPreviewedGoogleDocId(undefined)}
                      className="underline text-blue-700 cursor-pointer"
                    >
                      Close Preview
                    </a>
                  </p>
                  <iframe
                    // key={Math.random()}
                    height="500"
                    src={`${getLinkToGoogleDocWithId(
                      previewedGoogleDocId
                    )}?embedded=true`}
                  />
                </>
              )}
            </>
          )}

        {runnerMode || (
          <button className={testButtonStyles} onClick={onDownloadClick}>
            SAVE
          </button>
        )}
        <p>{statusMessage}</p>
      </div>
    </FuserLoader>
  );

  async function onDownloadClick(event?: any) {
    if (event) event.preventDefault();
    setIsLoading(true); // start loading

    const block = blocks[index];
    const {
      inputToProcess,
      selectValue: downloadType,
      downloadName,
    } = block.data;
    const fileInput = inputToProcess;
    const processedInput = replacePlaceholders(fileInput, blocks);
    console.log("download block input:", processedInput);

    /* adds custom headings if specified, transposes the array if 
       required to make dimensions match */

    const format2dArray = (input: string) => {
      const inputLines = input.split("\n");
      if (inputLines.length === 1) {
        return processedInput;
      }
      const headingsBlockReference =
        inputLines[0].match(blockReferenceRegex)?.[0];
      const headings = (
        headingsBlockReference
          ? replacePlaceholders(headingsBlockReference, blocks)
          : inputLines[0]
      ).split(/\s*,\s*/g);

      let array = replacePlaceholders(inputLines[1], blocks);

      if (array.length !== headings.length) {
        const transposed = transpose(array);

        if (transposed[0].length === headings.length) {
          array = transposed;
        }
      }
      return [headings, ...array];
    };

    /* takes an input string with an optional line of comma separated headings 
       and a line of references to 1d arrays */

    const make2dArrayFromHeadingsAnd1dArrayReferences = (input: string) => {
      const result: any[] = [];

      const inputLines = input.split(/\n+/);

      const gridSections: (string[][] | string[])[] = inputLines.map(
        (inputLine: string, lineIndex: number) => {
          const blockReferencesOnThisLine =
            inputLine.match(blockReferenceRegex);

          // the line should be either placeholders or a comma separated list of headings
          if (lineIndex === 0) {
            const headings = blockReferencesOnThisLine
              ? replacePlaceholders(blockReferencesOnThisLine[0], blocks)
              : inputLine;
            return headings.split(",").map((heading: string) => heading.trim());
          }
          if (lineIndex > 0 && blockReferencesOnThisLine) {
            const columns = blockReferencesOnThisLine.map(
              (blockReference: string) => {
                // flatten to a 1d array
                const blockContent = ensureArray(
                  replacePlaceholders(blockReference, blocks)
                ).flat(Infinity);

                return blockContent;
              }
            );
            const maxColumnLength = Math.max(
              ...columns.map((column: string[]) => column.length)
            );
            const gridSection: any[] = [];
            for (let row = 0; row < maxColumnLength; row++) {
              gridSection.push(
                columns.map((column: string[]) => column[row] ?? "")
              );
            }
            return gridSection;
          } else {
            throw new Error("Invalid format");
          }
        }
      );

      for (const gridSection of gridSections) {
        if (Array.isArray(gridSection[0])) result.push(...gridSection);
        else result.push(gridSection);
      }

      // I think the commented code below is unnecessary but I'll keep it just in case
      // const maxRowLength = Math.max(
      //   ...gridSections.map((gridSection: string[][] | string[]) => {
      //     return (
      //       Array.isArray(gridSection[0]) ? gridSection[0] : gridSection
      //     ).length;
      //   })
      // );

      // const padArrayRightWithEmptyStrings = (array: any[]) => {
      //   return Array<any>(maxRowLength)
      //     .fill('')
      //     .map(
      //       (emptySlot: any, slotIndex: number) => array[slotIndex] ?? ''
      //     );
      // };

      // const paddedGridSections = gridSections.map((gridSection: any) => {
      //   return Array.isArray(gridSection[0])
      //     ? gridSection.map((row: string[]) =>
      //         padArrayRightWithEmptyStrings(row)
      //       )
      //     : padArrayRightWithEmptyStrings(gridSection);
      // });

      // for (const paddedGridSection of gridSections) {
      //   if (Array.isArray(paddedGridSection[0]))
      //     gridInput.push(...paddedGridSection);
      //   else gridInput.push(paddedGridSection);
      // }

      return result;
    };

    const make2dArrayFromString = (input: string) => {
      return evaluatePlaceholder(input, blocks)
        .split("\n")
        .map((line: string) => line.split(","));
    };

    try {
      switch (downloadType) {
        case "txt": {
          const blob = new Blob([processedInput], {
            type: "text/plain;charset=utf-8",
          });
          const url = URL.createObjectURL(blob);
          block.data.downloadLink = url;
          break;
        }
        case "html": {
          const blob = new Blob([processedInput], {
            type: "text/plain;charset=utf-8",
          });
          const url = URL.createObjectURL(blob);
          block.data.downloadLink = url;
          break;
        }
        case "docx": {
          const createDocx = await axios.post(
            `${backendURL}/create-docx`,
            {
              body: processedInput,
              creator: block.data.creator,
            },
            { responseType: "arraybuffer" }
          );
          const blob = new Blob([createDocx.data], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          const url = URL.createObjectURL(blob);
          block.data.downloadLink = url;
          break;
        }
        case "json": {
          const blob = new Blob([JSON.stringify(processedInput)], {
            type: "application/json",
          });
          const url = URL.createObjectURL(blob);
          block.data.downloadLink = url;
          break;
        }
        case "csv": {
          const gridInput = (
            is2dArray(processedInput)
              ? format2dArray
              : blockReferenceRegex.test(inputToProcess) &&
                  Array.isArray(processedInput)
                ? make2dArrayFromHeadingsAnd1dArrayReferences
                : make2dArrayFromString
          )(inputToProcess);

          const escapeSpecialChars = (value: string) => {
            // replace any " with "" and finally wrap the whole string with "
            return `"${value.replace(/"/g, '""')}"`;
          };

          const blob = new Blob(
            [
              gridInput
                .map((row: any) => row.map(escapeSpecialChars).join(","))
                .join("\n"),
            ],
            {
              type: "text/plain;charset=utf-8",
            }
          );
          const url = URL.createObjectURL(blob);
          block.data.downloadLink = url;
          break;
        }
        case "tsv": {
          const gridInput = is2dArray(processedInput)
            ? processedInput
            : make2dArrayFromHeadingsAnd1dArrayReferences(inputToProcess);

          // escaping newlines may not work consistently across all applications so I have left it out

          // console.log(gridInput.map((row: any) => row.join('\t')).join('\n'));
          const blob = new Blob(
            [gridInput.map((row: any) => row.join("\t")).join("\n")],
            {
              type: "text/plain;charset=utf-8",
            }
          );
          const url = URL.createObjectURL(blob);
          block.data.downloadLink = url;
          break;
        }
        default:
          break;
      }

      // const blockUpdater = (blocks: any) => {
      //   const newBlocks = [...blocks];
      //   newBlocks[index] = {
      //     ...block,
      //     data: {
      //       ...block.data,
      //       type: downloadType,
      //       download: fileInput,
      //       run: true,
      //     },
      //     updatedBlock: true,
      //   };
      // };

      if (event) {
        const downloadLink = document.createElement("a");
        downloadLink.href = block.data.downloadLink;
        downloadLink.download =
          processedDownloadName ||
          `${downloadName || `Block_${index}`}.${block.data.selectValue}`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setStatusMessage("Download has started");
      } else {
        setStatusMessage("Saved");
      }
    } catch (error) {
      setStatusMessage(error?.message);
    } finally {
      setIsLoading(false);
      setActivityLog((prevLog: string[]) => [
        ...prevLog,
        `Saved download block at index: ${index}`,
      ]);
    }
    // if (!runnerMode) setBlocks(blockUpdater);
    // else return blockUpdater;
  }

  function loadGoogleDocData() {
    return axios
      .post(`${backendURL}/google/docs/dataFromIds`, {
        ids: block.data.googleDocIds,
      })
      .then(({ data: { dataForGoogleDocWithId } }) => {
        // some of them may have been deleted since they were last generated, so we don't attempt to show them
        block.data.googleDocIds = block.data.googleDocIds.filter(
          (googleDocId: string) =>
            Object.keys(dataForGoogleDocWithId).includes(googleDocId)
        );
        setDataForGoogleDocWithId(dataForGoogleDocWithId);
      })
      .catch(() => console.log("Error loading titles"));
  }

  function getLinkToGoogleDocWithId(id: string) {
    return `https://docs.google.com/document/d/${id}/edit`;
  }

  function getCopyLinkToGoogleDocWithId(id: string) {
    return `https://docs.google.com/document/d/${id}/copy`;
  }
};

export default DownloadBlock;
