import React, {
  FC,
  useState,
  ChangeEvent,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Helmet } from 'react-helmet';
//import { Tooltip }  from "react-tooltip";
//import 'react-tooltip/dist/react-tooltip.css'
import 'firebase/auth';
import axios from 'axios';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useAuthUser, useAuthHeader } from 'react-auth-kit';
import Block from '../../models/Block';
import Theme from '../../models/Theme';
import FuserSidebar from './FuserSidebar';
import FuserMain from './FuserMain';
import FuserContext from '../../context/FuserContext';
import ReactGA from 'react-ga4';
import ShareModal from './ShareModal';
import {
  blockDataKeysHoldingBlockNumbers,
  blockDataKeysHoldingReferences,
  dangerousBlockTypes,
} from '../../constants/blocks';
import { useCookies } from 'react-cookie';
import { backendURL } from '../../constants/environmental';
import { updateProperty } from '../../utils/object';
import { updateAtIndex } from '../../utils/array';
import { hotjar } from 'react-hotjar';
import ReportIssueDialog from '../../components/ReportIssueDialog';
hotjar.initialize({ id: 5031132, sv: 6 });
// hotjar.identify('USER_ID', { userProperty: 'value' });

export interface FuserPageProps {
  className?: string;
}

const themes: Theme[] = [
  { name: 'Red Theme', blockColor: 'bg-red-500', menuColor: 'bg-darkred' },
  { name: 'Blue Theme', blockColor: 'bg-blue-500', menuColor: 'bg-darkblue' },
  {
    name: 'Green Theme',
    blockColor: 'bg-green-500',
    menuColor: 'bg-darkgreen',
  },
  // Add more predefined themes
];

const FuserPage: FC<FuserPageProps> = () => {
  const user = useAuthUser()();
  const authHeader = useAuthHeader()();

  const userId = user?.id ?? '';
  const [hasAccess, setAccess] = useState(false);
  const [hasEditorAccess, setHasEditorAccess] = useState(false);
  const [blocksHaveLoaded, setBlocksHaveLoaded] = useState(false);
  const location = useLocation();

  // check for a url ID
  let toolId = '';
  const { blocksId: lastSegment } = useParams();
  if (lastSegment !== '0' && lastSegment !== undefined) {
    toolId = lastSegment;
  }

  const navigate = useNavigate();
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [isLoading, setIsLoading] = useState<boolean[]>([]); // New state for loading
  const [activityLog, setActivityLog] = useState<string[]>([]);

  const [notification, setNotification] = useState<string | null>(null);
  const historyRef = useRef<Block[][]>([]);
  const historyIndexRef = useRef(-1);

  useEffect(() => {
    if (!user) {
      navigate(`/fusion/${toolId}`);
    }
  }, []);

  const [collapsedBlocks, setCollapsedBlocks] = useState<string[]>([]);

  const [runnerIndex, setRunnerIndex] = useState<number | undefined>(undefined);
  const [runnerIndexHistory, setRunnerIndexHistory] = useState<number[]>([]);

  useEffect(() => {
    if (runnerIndex === undefined) {
      return;
    }
    if (runnerIndex >= blocks.length) {
      // const indexOfFirstChatBlock = blocks.findIndex(
      //   ({ type }: any) => type === "blockdiv-chat"
      // );
      // if (indexOfFirstChatBlock !== -1) {
      // }
    } else {
      setRunnerIndexHistory(history => [...history, runnerIndex]);
    }
  }, [runnerIndex]);

  const [runnerMode, setRunnerMode] = useState(true);
  const [stillRunning, setStillRunning] = useState(true);

  // console.log('collapsed:', collapsedBlocks);

  const [blockCancellationState, setBlockCancellationState] = useState({
    cancellingBlocks: false,
    idsOfBlocksToCancel: [],
    idOfBlockToRunAfterwards: undefined,
  });

  const cancellingBlocksMemo = useMemo(
    () => blockCancellationState.cancellingBlocks,
    [blockCancellationState]
  );

  // const updatedBlocks = useMemo(
  //   () => blocks.map((block: Block) => block.updatedBlock),
  //   [blocks]
  // );

  useEffect(() => {
    if (!runnerMode) {
      return;
    }

    for (let i = 0; i < blocks.length; i++) {
      if (blocks[i].updatedBlock) {
        // update blocks
        // console.log('block', i, 'has updated:', blocks);
        setBlocks(blocks =>
          blocks.map((block: any, blockIndex: number) => {
            if (blockIndex === i) {
              return { ...block, updatedBlock: false };
            } else {
              if (block.saveQueued) {
                saveRunnerModeBlockData(block);
                block.saveQueued = false;
              }
              return block;
            }
          })
        );

        const indexOfFirstChatBlock = blocks.findIndex(
          ({ type }: any) => type === 'blockdiv-chat'
        );
        if (
          blocks[i].type === 'blockdiv-if-else' ||
          blocks[i].type === 'blockdiv-jump'
        ) {
          setRunnerIndex(+blocks[i].data.response[0]);
          break;
        }
        // once the end is reached go back to chat block if it exists
        if (indexOfFirstChatBlock !== -1) {
          /* if the only block is a chatblock, set the runner index to 1 since
             the block runner will only trigger after the runner index changes 
             to 0 from a different number, then the next effect below runs the 
             block again */
          if (blocks.length === 1) {
            if (runnerIndex === 0) {
              setRunnerIndex(1);
              setBlocks(blocks => {
                blocks[0].updatedBlock = true;
                return blocks;
              });
            }
            break;
          } else if (i + 1 >= blocks.length) {
            setRunnerIndex(indexOfFirstChatBlock);
            break;
          }
        }
        // if (runnerIndex != undefined && runnerIndex >= i) setRunnerIndex(i+1); // can change past blocks to where you are now but not future blocks
        setRunnerIndex(i + 1);
        // console.log('runner index updated ', i + 1);
        break;
      }
    }
  }, [blocks]);

  // this runs the chat block again after sending a message if it is the only block
  useEffect(() => {
    if (
      runnerIndex === 1 &&
      blocks.length === 1 &&
      blocks[0].type === 'blockdiv-chat'
    ) {
      setRunnerIndex(0);
    }
  }, [runnerIndex]);

  // console.log(blocks);

  const getRunnerModeDataForBlock = ({
    blocktype,
    data: {
      inputToProcess,
      response,
      checkedRadioValue,
      googleDocIds,
      temporaryExtraInfo,
      permanentExtraInfo,
      dontSendToOpenAI,
      nextMessageStartIndex,
      nextMessageEndIndex,
    },
  }: any) => {
    // save the ids along with the responses in case the creator changes the tool structure
    const runnerModeData: any = { response };

    if (blocktype === 'question' && checkedRadioValue) {
      runnerModeData.checkedRadioValue = checkedRadioValue;
    }

    if (blocktype === 'download' && googleDocIds) {
      runnerModeData.googleDocIds = googleDocIds;
    }

    if (blocktype === 'chat') {
      Object.assign(runnerModeData, {
        inputToProcess,
        temporaryExtraInfo,
        permanentExtraInfo,
        dontSendToOpenAI,
        nextMessageStartIndex,
        nextMessageEndIndex,
      });
    }

    return runnerModeData;
  };

  const saveRunnerModeBlockData = async (block: any) => {
    try {
      console.log(
        'saving runner mode data for block',
        blocks.findIndex(({ id }) => id === block.id)
      );
      let lastRunnerIndex =
        runnerIndex !== undefined &&
        Number.isInteger(runnerIndex) &&
        0 <= runnerIndex &&
        runnerIndex < blocks.length
          ? runnerIndex
          : null;

      if (lastRunnerIndex === blocks.length - 1 && !stillRunning) {
        lastRunnerIndex = null;
      }

      const blockData = getRunnerModeDataForBlock(block);

      setRunnerModeBlocksData((previous: any) => {
        let oldData = previous[block.id];
        if (!oldData) {
          previous[block.id] = {};
          oldData = previous[block.id];
        }
        Object.assign(oldData, blockData);
        return previous;
      });

      await Promise.all([
        axios.patch(
          `${backendURL}/user/${userId}/tools/${toolId}/access`,
          {
            lastRunnerIndex,
          },
          {
            headers: {
              Authorization: authHeader,
            },
          }
        ),
        axios.put(
          `${backendURL}/user/${userId}/tools/${toolId}/responses/${block.id}`,
          blockData,
          {
            headers: {
              Authorization: authHeader,
            },
          }
        ),
      ]);
    } catch (e) {
      console.log('Error saving runner mode data:', e);
    }
  };

  const [toolMetadata, setToolMetadata] = useState<any>({ tags: [] });

  const updateToolMetadata = (updatedProperties: any) => {
    return setToolMetadata((previous: any) => ({
      ...previous,
      ...updatedProperties,
    }));
  };

  useEffect(() => {
    if (blocksHaveLoaded && runnerMode && toolMetadata.autosaveMode) {
      console.log(
        'saving runner mode block data, new runner index:',
        runnerIndex
      );
      // this effect will fire on page load, runnerindex could be 0 if that is the last saved runner index
      if (runnerIndex && runnerIndex > 0) {
        saveRunnerModeBlockData(blocks[runnerIndex - 1]);
      }
    }
  }, [runnerIndex, blocksHaveLoaded, runnerMode, toolMetadata.autosaveMode]);

  const toggleRunnerMode = () => {
    // restart runner mode if creator finished last time
    // if (runnerMode && runnerIndex == blocks.length - 1)
    //   setWaitingForClearUnsavedResponses(true);
    setRunnerMode(runnerMode => !runnerMode);
  };

  const [waitingForClearUnsavedResponses, setWaitingForClearUnsavedResponses] =
    useState<boolean>(false);

  const clearUnsavedResponses = () => {
    const blockIdsOfRunnerModeBlocksData = Object.keys(runnerModeBlocksData);
    setBlocks((blocks: any) => {
      const newBlocks = blocks.map((block: any) => {
        const newData = blockIdsOfRunnerModeBlocksData.includes(block.id)
          ? runnerModeBlocksData[block.id]
          : { response: [] };
        return {
          ...block,
          responseCleared: true,
          data: {
            ...block.data,
            ...newData,
          },
        };
      });
      //console.log('new blocks:', newBlocks);
      return newBlocks;
    });
  };

  // starts runner mode once all responses are cleared
  useEffect(() => {
    if (
      waitingForClearUnsavedResponses &&
      blocks.every((block: any) => block.responseCleared)
    ) {
      //console.log('in waitingforclearresponse effect');
      setWaitingForClearUnsavedResponses(false);

      // delete responseCleared properties
      setBlocks((blocks: any) =>
        blocks.map((block: any) => {
          const { responseCleared, ...restOfBlock } = block;
          return restOfBlock;
        })
      );
      setRunnerMode(true);
      // console.log('last saved runner index', lastSavedRunnerIndex);
      setRunnerIndex(toolMetadata.lastSavedRunnerIndex ?? 0);
      setStillRunning(true);
    }
  }, [waitingForClearUnsavedResponses, blocks]);

  const [restartQueued, setRestartQueued] = useState(false);

  const restartRunnerMode = () => {
    if (restartQueued) {
      return;
    }
    setRestartQueued(true);
    setStillRunning(false);
  };

  const anyBlocksLoading = isLoading.some(loading => loading);

  // console.log({ restartQueued });

  // restart when no blocks are loading and a restart has been requested
  useEffect(() => {
    if (!restartQueued || anyBlocksLoading) {
      return;
    }
    // may need to clear updatedblock properties too
    setStillRunning(true);
    setRunnerIndex(0);
    setRestartQueued(false);
  }, [anyBlocksLoading, restartQueued]);

  const pauseRunnerMode = (index?: number): void => {
    setStillRunning(false);
  };

  const resumeRunnerMode = (index?: number): void => {
    setStillRunning(true);
  };

  // UNDO AND REDO FUNCTIONALITIES
  useEffect(() => {
    // Add to history only if we're at the "latest" state.
    if (historyIndexRef.current === historyRef.current.length - 1) {
      historyRef.current.push([...blocks]);
      historyIndexRef.current++;
    }
  }, [blocks]);

  function undoChanges() {
    if (historyIndexRef.current > 0) {
      historyIndexRef.current--;
      setBlocks(historyRef.current[historyIndexRef.current]);
      setNotification('Undoing change');
      setTimeout(() => {
        setNotification(null);
      }, 1000);
    }
  }

  function redoChanges() {
    if (historyIndexRef.current < historyRef.current.length - 1) {
      historyIndexRef.current++;
      setBlocks(historyRef.current[historyIndexRef.current]);
      setNotification('Redoing change');
      setTimeout(() => {
        setNotification(null);
      }, 1000);
    }
  }

  // useEffect(() => {
  //   const keyDownHandler = (event: KeyboardEvent) => {
  //     if (event.ctrlKey && event.key === 'z') {
  //       event.preventDefault();
  //       undoChanges();
  //     } else if (event.ctrlKey && event.key === 'r') {
  //       event.preventDefault();
  //       redoChanges();
  //     }
  //   };

  //   window.addEventListener('keydown', keyDownHandler);

  //   // cleanup function
  //   return () => {
  //     window.removeEventListener('keydown', keyDownHandler);
  //   };
  // }, [undoChanges, redoChanges]);

  const [imageURLsForBlockWithId, setImageURLsForBlockWithId] = useState<
    Record<string, string[]>
  >({});

  const [blockIds, setBlockIds] = useState<string[]>([]);

  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [isAuthor, setIsAuthor] = useState<boolean | undefined>(undefined);
  const [buildModeBlocks, setBuildModeBlocks] = useState<any>(undefined);

  const [runnerModeBlocksData, setRunnerModeBlocksData] = useState<any>(false);

  const [errorLoadingBlocks, setErrorLoadingBlocks] = useState<any>('');

  const LoadBlock = async (responsesFromUserWithId?: string) => {
    console.log('Loading tool');

    if (lastSegment !== '0') {
      try {
        const res = await axios.get(
          `${backendURL}/blocks/${lastSegment}?responsesFromUserWithId=${responsesFromUserWithId ?? ''}`,
          {
            headers: {
              Authorization: authHeader,
            },
          }
        );

        const canEdit =
          user?.loggedin == 'false' ||
          user?.id == res.data.authorId._id ||
          res.data.editorAccess;

        if (canEdit) {
          setAccess(true);
          setRunnerMode(false);
        }

        if (res.data.chargeFrequency === 'monthly') {
          const {
            reviewDate,
            subscriptionCharge,
            subscriptionDueCancellation,
          } = res.data;
          updateToolMetadata({
            subscriptionInfo: {
              reviewDate,
              subscriptionCharge,
              subscriptionDueCancellation,
            },
          });
        } else {
          updateToolMetadata({ subscriptionInfo: undefined });
        }

        if (responsesFromUserWithId) {
          updateToolMetadata({ autosaveMode: false });
        } else if (res.data?.autosaveMode !== undefined) {
          updateToolMetadata({ autosaveMode: res.data.autosaveMode });
        }

        if (res.data?.runnerModeResponses) {
          setRunnerModeBlocksData(res.data?.runnerModeResponses);
        }
        if (res.data?.lastRunnerIndex) {
          updateToolMetadata({
            lastSavedRunnerIndex: res.data.lastRunnerIndex,
          });
        }
        if (res.data?.tags) {
          updateToolMetadata({ tags: res.data.tags });
        }

        const loadedBlocks = res.data.fields;

        updateToolMetadata({
          authorId: res.data.authorId._id,
          authorName: res.data.authorId.name,
          // coverPhoto: res.data.authorId.coverPhoto ?? "",
          authorCoverPhoto: res.data.authorId.profileImage,
          id: res.data._id,
          isPublished: res.data.isPublished,
          furtherInfo: res.data.furtherInfo,
          isRequestPublished: res.data.isRequestPublished,
          coverPhoto: res.data.coverPhoto,
          price: res.data.price,
          priceFrequency: res.data.priceFrequency,
          title: res.data.name,
          chosenCategories: res.data.categories,
          description: res.data.description,
          customMeta: res.data.customMeta,
          customUrlSlug: res.data.customUrlSlug,
          hideFromSearchResults: res.data.hideFromSearchResults,
        });

        // console.log('loaded blocks: ', loadedBlocks);
        // console.log('response data: ', res.data);

        setIsAuthor(res.data.authorId._id === user?.id);

        // Set the loaded blocks in your component state
        setBlocks(
          canEdit
            ? loadedBlocks
            : loadedBlocks.map((block: any) => ({
                ...block,
                data: {
                  ...block.data,
                  response: [],
                },
              }))
        );
        setIsLoading(Array(loadedBlocks.length).fill(false));
        setBlocksHaveLoaded(true);
        setHasEditorAccess(res.data.editorAccess);
        // next step is the effect below
      } catch (e) {
        const error = e as any;
        console.error('Error loading blocks', error);
        let errorMessage = 'Error loading blocks';
        const errorStatus = error?.response?.status;
        if (errorStatus) {
          errorMessage += ': ' + errorStatus;
          const statusMessage = error.response?.statusText;
          if (statusMessage) {
            errorMessage += ' - ' + statusMessage;
          }
        }
        setErrorLoadingBlocks(errorMessage);
        // Handle error loading blocks
        // You can show an error message or perform any other necessary action
      }
    } else {
      // on a blank tool
      setRunnerMode(false);
      setAccess(true);
    }
  };

  useEffect(() => {
    if (blocksHaveLoaded && isAuthor !== undefined) {
      const userOwnsBlock =
        isAuthor || user?.loggedin === 'false' || hasEditorAccess;
      if (userOwnsBlock) {
        setIsOwner(true);
        //console.log("you are the owner")
      } else {
        setIsOwner(false);
        const params = new URLSearchParams(location.search);
        params.set('runner', 'true');
        setCollapsedBlocks(blocks.slice(1).map(({ id }) => id));
        setWaitingForClearUnsavedResponses(true);
        clearUnsavedResponses();
        //console.log("Not the owner, just a user")
      }
    }
  }, [blocksHaveLoaded, isAuthor]);

  useEffect(() => {
    LoadBlock();
  }, []);

  // const handleToggle = () => {
  //   //setIsPublished(!isPublished);
  //   setIsRequestPublished(!isRequestPublished);
  // };

  const getBlockData = (blockData: {
    id: string;
    type: string;
    data: object;
    collapseAfterRunning?: boolean;
  }) => {
    //console.log('data: ', blockData.data);
    return {
      ...blockData,
      blocktype: blockData.type.split('-')[1],
      _key: uuidv4(),
    };
  };

  const [saveBlockStatusMessage, setSaveBlockStatusMessage] =
    useState<string>('');

  const savingBlocksMessage = 'Saving blocks...';
  const successfulSaveStatusMessage = 'Saved successfully!';

  useEffect(() => {
    if (saveBlockStatusMessage === successfulSaveStatusMessage) {
      setTimeout(() => setSaveBlockStatusMessage(''), 4000);
    }
  }, [saveBlockStatusMessage]);

  const saveBlock = async () => {
    setSaveBlockStatusMessage(savingBlocksMessage);
    ReactGA.event('saved_tool');

    const nonNullBlocks = blocks.filter(
      block => block && Object.keys(block).length > 0
    );

    setBlocks(
      blocks.filter((block: any) => block?.type?.startsWith('blockdiv'))
    );

    const formattedBlocks = nonNullBlocks
      .map(block => {
        //console.log(block.type);
        if (!block?.type?.startsWith('blockdiv')) {
          return null;
        } else {
          return getBlockData(block);
        }
      })
      .filter(block => block !== null); // filter out any null blocks

    // unpublish if the tool contains a html preview or javascript block
    const blockTypes = formattedBlocks.map((block: any) => block.blocktype);

    const containsDangerousBlock = dangerousBlockTypes.some(dangerousType =>
      blockTypes.includes(dangerousType)
    );

    const newIsPublished =
      user?.loggedin !== 'false' && containsDangerousBlock ? false : undefined;

    if (newIsPublished === false) {
      updateToolMetadata({ isPublished: false });
    }

    if (!toolMetadata.title) {
      setSaveBlockStatusMessage('Please enter a title before saving.');
      return;
    }
    if (blocks.length === 0) {
      setSaveBlockStatusMessage('Please add at least one block before saving.');
      return;
    }

    const prettifiedCustomUrlSlug = toolMetadata.customUrlSlug
      ?.toLowerCase()
      .replace(/\s+/g, '-');

    updateToolMetadata({ customUrlSlug: prettifiedCustomUrlSlug });

    try {
      // new code
      /*
      const formattedBlocksSave = formattedBlocks.map((item) => {
        if ('data' in item) {
          let newItem = { ...item }; // Create a shallow copy of the item
          if ('processedInput' in newItem.data && Array.isArray((item.data as any).processedInput)) {
            (item.data as any).processedInput = JSON.stringify((item.data as any).processedInput);
          }
          //if ('response' in newItem.data && Array.isArray((item.data as any).response)) {
          //  (item.data as any).response = JSON.stringify((item.data as any).response);
         // }
          return newItem;
        } else {
          return item; // If there's no 'data', return the item unchanged
        }
      });
      */

      // end of new code

      const res = await axios.post(
        `${backendURL}/blocks/save`,
        {
          name: toolMetadata.title || `${user?.username} Untitled Blocks`,
          description: toolMetadata.description,
          furtherInfo: toolMetadata.furtherInfo,
          _id: toolId,
          fields: formattedBlocks, //formattedBlocksSave,
          authorId: toolMetadata.authorId || user?.id, // authorId first otherwise it saves as admin id
          price: toolMetadata.price,
          priceFrequency: toolMetadata.priceFrequency,
          isRequestPublished: toolMetadata.isRequestPublished,
          isPublished: newIsPublished,
          categories: toolMetadata.chosenCategories.map(({ _id }: any) => ({
            _id,
          })),
          tags: toolMetadata.tags,
          hideFromSearchResults: toolMetadata.hideFromSearchResults,
          customMeta: toolMetadata.customMeta,
          customUrlSlug: prettifiedCustomUrlSlug,
        },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );

      const savedBlock = res.data.result;

      if (toolMetadata.isPublished && newIsPublished === false) {
        setSaveBlockStatusMessage(
          `${successfulSaveStatusMessage} As your block contains a HTML preview/Custom Javascript block, for safety reasons your tool will need reviewing by an admin before it can be published.`
        );
      } else {
        setSaveBlockStatusMessage(successfulSaveStatusMessage);
      }
      if (lastSegment === '0') {
        window.location.href = `/fuser/${res.data._id}`;
      }
      return savedBlock;
    } catch (error) {
      console.error('Error saving blocks', error);
      alert(`error: ${error}`);
    }
  };

  // Any html element with onChange as handleChange will add its value to its blocks data object with its name as the key
  const handleChange = (
    index: number,
    event: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    let { name, value } = event.target;
    // console.log(name, value);
    if (event.target.type === 'radio') {
      name = 'checkedRadioValue';
    }

    setBlocks((blocks: Block[]) => {
      const newBlocks = [...blocks];
      newBlocks[index] = {
        ...newBlocks[index],
        data: {
          ...newBlocks[index].data,
          [name]: value,
        },
      };
      return newBlocks;
    });
  };

  const updateBlockData =
    (index: number) => (property: string, newValue: any) => {
      const oldBlock = blocks[index];
      const newBlock = updateProperty(
        ['data', property],
        newValue,
        oldBlock
      ) as Block;
      if (typeof newBlock === 'object') {
        setBlocks((blocks: Block[]) => updateAtIndex(index, newBlock, blocks));
      }
    };

  const initialBlockStyles =
    //'border-2 my-4 p-4 rounded-xl gap-2 text-black shadow-xl bg-gradient-to-b from-blue-100 to-purple-200 transition-opacity duration-1000 ease-in-out opacity-0 animate-fade-in dark:bg-neutral-800';
    'border-2 my-4 p-4 flex flex-col rounded-xl gap-2 text-black shadow-xl bg-gradient-to-b from-blue-100 to-purple-200 transition-opacity duration-1000 ease-in-out opacity-0 animate-fade-in dark:bg-neutral-800';
  //`border-2 my-4 p-4 flex ${collapsedBlocks && runnerMode?'flex-row' : 'flex-col'} rounded-xl gap-2 text-black shadow-xl bg-gradient-to-b from-blue-100 to-purple-200 transition-opacity duration-1000 ease-in-out opacity-0 animate-fade-in dark:bg-neutral-800`;

  const blockStyles = runnerMode
    ? `${initialBlockStyles} ` // add any additional runner mode styles here
    : initialBlockStyles;

  //blockStyles = runnerMode && collapsedBlocks? `${blockStyles} flex-row` // add any additional runner mode styles here
  //  : initialBlockStyles + " flex-col";

  const textAreaRefs = useRef<Record<string, HTMLTextAreaElement | null>>({});
  const blockRefs = useRef<Record<string, HTMLDivElement | null>>({});

  const focusedTextarea = useRef<HTMLTextAreaElement | null>(null);
  const [textareaValue, setTextareaValue] = useState(''); // Replace '' with the initial value of your textarea
  const cursorPositionRef = useRef<number | null>(null);

  const [selectedBlockId, setSelectedBlockId] = useState<string | null>('');
  const [selectedTextareaId, setSelectedTextareaId] = useState<number | null>(
    0
  );

  const [selectedBlockReference, setSelectedBlockReference] = useState('');

  useEffect(() => {
    if (blocks.length <= 1) {
      setSelectedBlockReference('');
    } else if (selectedBlockReference === '') {
      setSelectedBlockReference('0:input');
    }
  }, [blocks]);

  const setFocusedTextArea = (
    blockId: string | null,
    textareaId: number | null
  ) => {
    if (blockId !== null && textareaId !== null) {
      focusedTextarea.current =
        textAreaRefs.current[`${blockId}:${textareaId}`];
      focusedTextarea.current?.focus();
    }
  };

  useEffect(() => {
    if (blockIds.length > 0) {
      setSelectedBlockId(blockIds[blockIds.length - 1]);
    }
  }, [blockIds]);

  // This function can be called when a textarea gets focused
  const handleTextareaFocus = (
    event: React.FocusEvent<HTMLTextAreaElement>,
    blockId: string,
    textareaId: number
  ) => {
    focusedTextarea.current = textAreaRefs.current[`${blockId}:${textareaId}`];
    setSelectedBlockId(blockId);
    setSelectedTextareaId(textareaId);
  };

  const [isFullScreen, setIsFullScreen] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  // Then a useEffect hook to apply the cursor position
  useEffect(() => {
    //console.log(focusedTextarea.current);
    if (cursorPositionRef.current !== null && focusedTextarea.current) {
      focusedTextarea.current.selectionStart = cursorPositionRef.current;
      focusedTextarea.current.selectionEnd = cursorPositionRef.current;
      cursorPositionRef.current = null;
    }
  }, [textareaValue, focusedTextarea]);

  const [isJsonVisible, setIsJsonVisible] = useState(false);

  const toggleJsonVisibility = () => {
    setIsJsonVisible(!isJsonVisible);
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [blockToShare, setBlockToShare] = useState<string>('');
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shareMessage, setShareMessage] = useState<string>('');

  const [cookies] = useCookies(['_auth_state']);
  const [minimalMode, setMinimalMode] = useState<boolean>(
    cookies._auth_state?.minimalMode === 'true'
  );

  const [blockScrollingIntoView, setBlockScrollingIntoView] =
    useState<boolean>(false);

  const [showReportIssueDialog, setShowReportIssueDialog] =
    useState<boolean>(false);

  // this adds a 'null' block containing a dropdown to choose it's type
  function addBlock(index?: number) {
    const newBlock = {
      id: uuidv4(),
      type: '',
      data: {},
      ref: React.createRef<HTMLTextAreaElement>(),
    };

    if (index !== undefined) {
      setBlocks((blocks: Block[]) => {
        const newBlocks = [...blocks];
        newBlocks.splice(index + 1, 0, newBlock);

        const blockIndicesToChange = blocks
          .map((_, blockIndex) => blockIndex)
          .filter(blockIndex => blockIndex >= index + 1);

        const blockNumberReplacement = (blockNumberString: string) => {
          return blockIndicesToChange.includes(+blockNumberString)
            ? +blockNumberString + 1
            : blockNumberString;
        };

        const atReferencesNeedingUpdate = new RegExp(
          `@Block(${blockIndicesToChange.join('|')})(?=\\W|$)`,
          'g'
        );

        const atReferenceReplacement = (
          atReference: string,
          blockNumberString: string
        ) => {
          return `@Block${+blockNumberString + 1}`;
        };

        //console.log(atReferencesNeedingUpdate);

        const blockReferenceNeedingUpdate = new RegExp(
          `<(${blockIndicesToChange.join('|')}):(input|output)>`,
          'g'
        );

        //console.log("blockIndicesToChange",blockIndicesToChange);

        const blockReferenceReplacement = (blockReference: string) => {
          const regex = /<(\d+):(output|input)>/g;
          let match: RegExpExecArray | null;
          while ((match = regex.exec(blockReference)) !== null) {
            const newRef = parseInt(match[1]) + 1;
            // console.log(
            //   'match',
            //   match,
            //   '<' + newRef.toString() + ':' + match[2] + '>'
            // );
            return '<' + newRef.toString() + ':' + match[2] + '>';
          }
        };

        return newBlocks.map(block => {
          const newDataKeyValuePairs = [
            ...blockDataKeysHoldingReferences
              .filter(key => block.data?.[key]?.toString() !== undefined)
              .map(key => [
                key,
                block.data[key]
                  .toString()
                  ?.replace(
                    blockReferenceNeedingUpdate,
                    blockReferenceReplacement
                  )
                  ?.replace(atReferencesNeedingUpdate, atReferenceReplacement),
              ]),
            ...blockDataKeysHoldingBlockNumbers
              .filter(key => block.data?.[key] !== undefined)
              .map(key => [key, blockNumberReplacement(block.data[key])]),
          ];
          return {
            ...block,
            data: {
              ...block.data,
              ...Object.fromEntries(newDataKeyValuePairs),
            },
          };
        });
      });

      setIsLoading(oldIsLoading => {
        const newIsLoading = [...oldIsLoading];
        newIsLoading.splice(index + 1, 0, false);
        return newIsLoading;
      });

      setActivityLog(prevLog => [
        ...prevLog,
        `Added new block at index: ${index + 1}`,
      ]);
    } else {
      setBlocks(prevBlocks => [...prevBlocks, newBlock]);
      setIsLoading(oldIsLoading => [...oldIsLoading, false]);
      setActivityLog(prevLog => [...prevLog, 'Added new block at the end']);
    }
  }

  const fuserContextValues = {
    LoadBlock,
    buildModeBlocks,
    setBuildModeBlocks,
    addBlock,
    setBlockScrollingIntoView,
    blockScrollingIntoView,
    anyBlocksLoading,
    restartQueued,
    restartRunnerMode,
    minimalMode,
    setMinimalMode,
    toolId,
    blocks,
    collapsedBlocks,
    blockStyles,
    runnerIndex,
    runnerMode,
    toggleRunnerMode,
    undoChanges,
    redoChanges,
    toggleSidebar,
    isFullScreen,
    setIsFullScreen,
    contentRef,
    isJsonVisible,
    toggleJsonVisibility,
    clearUnsavedResponses,
    hasAccess,
    pauseRunnerMode,
    resumeRunnerMode,
    stillRunning,
    setStillRunning,
    saveBlock,
    selectedTextareaId,
    selectedBlockId,
    setSelectedBlockId,
    selectedBlockReference,
    setSelectedBlockReference,
    isLoading,
    setIsLoading,
    activityLog,
    focusedTextarea,
    cursorPositionRef,
    setFocusedTextArea,
    isOwner,
    isAuthor,
    userId,
    textAreaRefs,
    blockRefs,
    imageURLsForBlockWithId,
    setBlocks,
    setActivityLog,
    setImageURLsForBlockWithId,
    handleChange,
    handleTextareaFocus,
    updateBlockData,
    waitingForClearUnsavedResponses,
    setWaitingForClearUnsavedResponses,
    blocksHaveLoaded,
    saveBlockStatusMessage,
    setSaveBlockStatusMessage,
    setRunnerIndex,
    runnerModeBlocksData,
    setRunnerModeBlocksData,
    errorLoadingBlocks,
    blockCancellationState,
    setBlockCancellationState,
    cancellingBlocksMemo,
    setCollapsedBlocks,
    blockToShare,
    setBlockToShare,
    shareModalOpen,
    setShareModalOpen,
    shareMessage,
    setShareMessage,
    isSidebarOpen,
    saveRunnerModeBlockData,
    runnerIndexHistory,
    setShowReportIssueDialog,
    toolMetadata,
    updateToolMetadata,
  };

  return (
    <FuserContext.Provider value={fuserContextValues}>
      <div
        ref={contentRef}
        className=' bg-white dark:bg-neutral-900 lg:mb-12'
        data-nc-id='BuilderPage'
      >
        <Helmet>
          <title>{`${!toolId ? 'Tool Maker - ' : toolMetadata.title ? `${toolMetadata.title} - ` : ''}Skillfusion`}</title>
          {/* <link
            rel='canonical'
            href={`https://skillfusion.ai/fusion/${lastSegment}`}
          /> */}
        </Helmet>
        {showReportIssueDialog && <ReportIssueDialog />}
        <div
          className='lg:flex runner-height'
          id='main-content'
        >
          <FuserMain />

          {isSidebarOpen && (
            <FuserSidebar
              title={toolMetadata.title}
              description={toolMetadata.description}
              isPublished={toolMetadata.isPublished}
              authorCoverPhoto={toolMetadata.authorCoverPhoto}
              authorId={toolMetadata.authorId}
              authorName={toolMetadata.authorName}
              isForSharedBlock={false}
              toolId={toolId}
              toggleSidebar={toggleSidebar}
            />
          )}
        </div>
      </div>
      <ShareModal
        isOpen={shareModalOpen}
        onClose={() => setShareModalOpen(false)}
      />
    </FuserContext.Provider>
  );
};

export default FuserPage;
