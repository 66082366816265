import React, { FC, useContext } from "react";
import axios from "axios";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import ReactGA from "react-ga4";
import FuserContext from "../../context/FuserContext";
import MyToolTips from "../../components/MyTooltip";
import Toggle from "../../components/Toggle";
import { backendURL } from "../../constants/environmental";

const IsPublishedSection: FC<{}> = () => {
  const {
    toolId,
    saveBlock,
    toolMetadata: {
      isRequestPublished,
      title,
      priceFrequency,
      price,
      description,
      chosenCategories,
    },
    updateToolMetadata,
  } = useContext(FuserContext);

  const authHeader = useAuthHeader()();
  const user = useAuthUser()();

  const togglePublish = async () => {
    if (toolId == "") {
      alert("Please save the tool first.");
    } else {
      await saveBlock();
      try {
        await axios.patch(
          `${backendURL}/blocks/publish/${toolId}`,
          {
            title,
            priceFrequency,
            price,
            description,
            categories: chosenCategories,
          },
          {
            headers: {
              Authorization: authHeader,
            },
          }
        );

        updateToolMetadata({ isRequestPublished: !isRequestPublished });
        ReactGA.event("publish_toggle");
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (user?.trialAccount) {
      return alert(
        "Please register your account to publish this tool. All of your data will be transferred."
      );
    }
    togglePublish();
  };

  return (
    <label className="flex items-center cursor-pointer">
      <Toggle toggled={isRequestPublished} onChange={handleToggle} />
      <div
        className={`ml-3 text-gray-700 font-medium ${
          isRequestPublished ? "text-green-500" : "text-red-500"
        }`}
      >
        {isRequestPublished ? "Publish Requested" : "Publish"}
      </div>{" "}
      &nbsp;{" "}
      <MyToolTips
        content="
              <p>Publishing:</p>
              <p>Press the toggle if you wish to publish your tool.</p>
              <p>We will review your tool before publishing, we may send feed back and recommend improvements</p>
              <p>Tools can not be a single prompt, check out our help files for more information.</p>
              "
        tipID="block-types"
        datatooltipplace="left"
      />
    </label>
  );
};

export default IsPublishedSection;
